import {useMetadataConfig} from "../useMetadataConfig";
import {useProjectState} from "../../project/projectContext";
import React, {useEffect, useState} from "react";

import {useMetaMappingTranslation} from "../MetaMappingContext";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {Stack} from "@mui/material";
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {SelectMuseumDialog} from "../../museum/SelectMuseum/SelectMuseumDialog";
import {damsFetch, damsSearch} from "../../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {PromisePool} from "@supercharge/promise-pool";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import Tab from "@mui/material/Tab";
import {ADD_MESSAGE, useSnackbarDispatch} from "../../snackbar/SnackbarContext";
import {useNavigate} from "react-router-dom";
import TextField from "@mui/material/TextField";
import AddCircleIcon from "@mui/icons-material/AddCircle";

/**
 * Renders a component that allows the user to select a museum and configure metadata mapping.
 *
 * @return {JSX.Element} The MappingConfig component.
 */
export const MappingConfig = () => {
    const t = useMetaMappingTranslation();

    const snackbarDispatch = useSnackbarDispatch();
    const navigate = useNavigate();

    const {hasArcheologyModule} = useProjectState();
    const [getMetadataConfig] = useMetadataConfig(hasArcheologyModule);

    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [selectedTabKey, setSelectedTabKey] = useState('image');
    const [collectionId, setCollectionId] = useState(-1);
    const [editedConfig, setEditedConfig] = useState({});
    const [existingMappings, setExistingMappings] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const metadataConfig = getMetadataConfig();

    const showSuccess = () => {
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: t('snackbarSuccessSaved', 'Lagret'),
                body: t('snackBarSuccessText', 'Lagring vellykket'),
                type: "success",
            },
        });
    };

    const showError = () => {
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: t('snackbarErrorSaving', 'Lagret'),
                body: t('snackbarErrorSavingText', 'Lagring feilet'),
                type: "error",
            },
        });
    };

    const handleTabChange = (event, key) => {
        setSelectedTabKey(key);
    };

    const getHrDocumentTypeName = (documentType) => {
        switch (documentType) {
            case 'image':
                return t('lblImage', 'Bilde');
            case 'audio':
                return t('lblAudio', 'Lyd');
            case 'video':
                return t('lblVideo', 'Film');
            case 'document':
                return t('lblDocument', 'Dokument');
            case 'model':
                return t('lblModel', 'Modell');
            case 'table':
                return t('lblTable', 'Tabell');
            default:
                return documentType;
        }
    };

    const getHrFieldName = (documentType, fieldType) => {
        const fields = metadataConfig[documentType]['fields'];
        if (fields[fieldType]) {
            return fields[fieldType].transDefaultValue;
        } else {
            return fieldType;
        }
    };

    /**
     * Remaps the loaded configuration data to the structure used by the GUI-components.
     * @returns {{}}
     */
    const remapEditedConfig = () => {
        let remapped = {};
        const documentTypes = Object.keys(editedConfig);
        for (let i = 0, max = documentTypes.length; i < max; i++) {
            const documentType = documentTypes[i];
            const fieldConfigs = Object.keys(editedConfig[documentType]);

            for (let j = 0, maxFieldConfigs = fieldConfigs.length; j < maxFieldConfigs; j++) {
                const fieldConfig = fieldConfigs[j];
                const mappedFields = editedConfig[documentType][fieldConfig];

                const out = {
                    [documentType]: {
                        [fieldConfig]: mappedFields
                    }
                };

                if (remapped[fieldConfig]) {
                    remapped[fieldConfig] = {...remapped[fieldConfig], ...out};
                } else {
                    remapped[fieldConfig] = out;
                }
            }
        }
        return remapped;
    };

    /**
     * Creates a document object for the specific field's configuration.
     * @param field
     * @param mappings
     * @returns {{documentType: string, schemaId: number, locale: string, title, collectionId: number, content, status: string}}
     */
    const createDoc = (field, mappings) => {
        return {
            collectionId: collectionId,
            schemaId: 1,
            locale: 'no',
            title: field,
            status: 'published',
            documentType: 'field_config',
            content: mappings
        };
    };

    /**
     * Updates the document object for the specific field's configuration.
     * @param mapped
     * @param existing
     * @returns {*&{content: (*)}}
     */
    const createUpdateDoc = (mapped, existing) => {
        return {
            ...existing,
            content: {
                ...existing.content,
                ...mapped
            }
        };
    };

    /**
     * Cancels operation, and returns to the DAMS homepage.
     */
    const cancel = () => {
        navigate('/');
    };

    /**
     * Saves the actual configuration to the server.
     */
    const save = () => {
        setButtonsDisabled(true);

        const remapped = remapEditedConfig();

        const docs = [];

        Object.keys(remapped).forEach(k => {
            const existingMapping = existingMappings.find(em => em.title === k);
            if (existingMapping) {
                // Merge with existing data.
                docs.push(createUpdateDoc(remapped[k], existingMapping));
            } else if (!existingMapping) {
                // Create new document.
                docs.push(createDoc(k, remapped[k]));
            }
        });

        PromisePool
            .withConcurrency(4)
            .for(docs)
            .process(async (doc) => {
                return damsFetch('/documents', {
                    method: 'POST',
                    body: JSON.stringify(decamelizeKeysDeep(doc))
                });
            })
            .then(data => {
                setExistingMappings(data.results);
                if (data.errors.length > 0) {
                    showError();
                } else {
                    showSuccess();
                    setButtonsDisabled(false);
                }
            });
    };

    /**
     * Handler triggered when the user clicks on a museum in the list.
     * @param museum
     */
    const selectMuseumHandler = museum => {
        setCollectionId(museum.collectionId);
    };

    /**
     * Adds a value to the editedConfig object based on the provided configKey and fieldKey.
     *
     * @param {string} configKey - The key of the config object.
     * @param {string} fieldKey - The key of the field within the config object.
     * @return {function} - A function that adds the value to the editedConfig object.
     */
    const add = (configKey, fieldKey) => () => {
        const el = document.getElementById(`input-${configKey}-${fieldKey}`);

        if (!el) {
            return;
        }

        const value = el.value;

        let out;
        if (!Object.keys(editedConfig).includes(configKey)) {
            out = {
                ...editedConfig,
                [configKey]: {
                    [fieldKey]: [value]
                }
            }
        } else {
            if (!editedConfig[configKey][fieldKey]) {
                out = {
                    ...editedConfig,
                    [configKey]: {
                        ...editedConfig[configKey],
                        [fieldKey]: [value]
                    }
                }
            } else {
                out = {
                    ...editedConfig,
                    [configKey]: {
                        ...editedConfig[configKey],
                        [fieldKey]: [...editedConfig[configKey][fieldKey], value]
                    }
                };
            }
        }

        // Add value to editedConfig object, and clear input field.
        setEditedConfig(out);
        el.value = '';
    };

    /**
     * Removes a value from the editedConfig object based on the provided configKey, fieldKey, and value.
     *
     * @param {string} configKey - The key of the config object.
     * @param {string} fieldKey - The key of the field within the config object.
     * @param {any} value - The value to be removed.
     * @return {void}
     */
    const remove = (configKey, fieldKey, value) => {
        const values = editedConfig[configKey][fieldKey];
        const ix = values.findIndex(v => v === value);
        values.splice(ix, 1);
        setEditedConfig({
            ...editedConfig,
            [configKey]: {
                ...editedConfig[configKey],
                [fieldKey]: values
            }
        });
    };

    /**
     * Displays the dialog that allows the user to select the museum he/she wants to use,
     * if no museum/collection is selected.
     *
     * @return {JSX.Element|null} The SelectMuseumDialog component or null.
     */
    const getSelectMuseumDialog = () => {
        return collectionId === -1 && <SelectMuseumDialog t={t}
                                                          selectedMuseumCallback={selectMuseumHandler}
                                                          collectionId={collectionId}
                                                          dialogTitle={t('metadataMappingTitle', 'Metadata mapping')}/>;
    };

    /**
     * Gets a configuration section, with all its fields, for the specific document type.
     * @param configKey
     * @returns {Element}
     */
    const getConfigSection = configKey => {
        const configSection = metadataConfig[configKey];
        if (!configSection) {
            return <></>;
        }

        return <Box key={`container-config-section-${configKey}`}>
            <Typography variant={"h6"}>
                {getHrDocumentTypeName(configKey)}
            </Typography>
            <Box>
                {Object.keys(configSection['fields']).map((f) => {
                    const field = configSection['fields'][f];
                    if (field.metadataMapper === false) {
                        return <></>;
                    } else {
                        return getConfigField(configKey, f, field);
                    }
                })}
            </Box>
        </Box>;
    };

    /**
     * Returns a JSX element that renders a list of edited values for a given config key and field key.
     *
     * @param {string} configKey - The key of the config object.
     * @param {string} fieldKey - The key of the field within the config object.
     * @return {JSX.Element} - A JSX element that renders a list of edited values.
     */
    const getEditedConfig = (configKey, fieldKey) => {
        if (!Object.keys(editedConfig).includes(configKey)) {
            return <></>;
        } else {
            return <List>
                {
                    editedConfig[configKey][fieldKey]?.map(v => {
                        return <ListItem key={v}>
                            <IconButton onClick={() => remove(configKey, fieldKey, v)}>
                                <DeleteIcon/>
                            </IconButton>
                            {v}
                        </ListItem>;
                    })
                }
            </List>;
        }
    };

    /**
     * Renders information about the existing configuration.
     * @returns {React.JSX.Element|unknown[]}
     */
    const getCurrentConfig = () => {
        if (Object.keys(editedConfig).length === 0) {
            return <></>;
        }

        function getMappedFields(configKey) {
            return Object.keys(editedConfig[configKey])
                .map((fieldKey) => {
                    if (isNaN(parseInt(fieldKey))) {
                        const hrDocumentTypeName = getHrDocumentTypeName(configKey)
                        const hrFieldName = getHrFieldName(configKey, fieldKey);
                        return <Box key={`${configKey}-${fieldKey}`}>
                            <Typography variant={"h6"}>{hrDocumentTypeName} - {hrFieldName}</Typography>
                            <ul>{getEditedConfig(configKey, fieldKey)}</ul>
                        </Box>;
                    }
                });
        }

        return Object.keys(editedConfig).map((configKey) => {
            return <Box key={`config-container-${configKey}`}>
                {getMappedFields(configKey)}
            </Box>
        });
    }

    /**
     * Renders a configuration field based on the provided config key, field key, and config field.
     *
     * @param {string} configKey - The key of the config object.
     * @param {string} fieldKey - The key of the field within the config object.
     * @param {Object} configField - The configuration field object.
     * @return {JSX.Element} - A JSX element representing the configuration field.
     */
    const getConfigField = (configKey, fieldKey, configField) => {
        if (!configField) {
            return <></>;
        }
        const key = `input-${configKey}-${fieldKey}`;
        return <Box key={`input-container-${configKey}-${fieldKey}`} sx={{marginBottom: '16px'}}>
            <Stack direction={"row"}>
                <TextField key={key}
                           id={key}
                           label={configField.transDefaultValue}
                           variant="filled"/>
                <Button onClick={add(configKey, fieldKey)}>
                    <AddCircleIcon/>
                </Button>
            </Stack>
        </Box>;
    };

    /**
     * Config called when existing config has been loaded.
     * @param data
     */
    const loadedConfigCallback = data => {
        let config = {};
        for (let i = 0, max = data.length; i < max; i++) {
            const content = data[i]['content'];
            const docTypes = Object.keys(content);
            for (let a = 0, maxDocTypes = docTypes.length; a < maxDocTypes; a++) {
                const docType = docTypes[a];

                // Add document type
                if (!config[docType]) {
                    config[docType] = {};
                }

                // Add fields
                for (let n = 0, maxFields = Object.keys(content[docType]).length; n < maxFields; n++) {
                    const fieldKey = Object.keys(content[docType])[n];
                    config[docType][fieldKey] = content[docType][fieldKey];
                }
            }
        }
        setEditedConfig(config);
        setExistingMappings(data);
        setLoaded(true);
    };

    /**
     * Hook used to load the already stored mapping configs.
     */
    useEffect(() => {
        if (collectionId > -1) {
            const searchParams = new URLSearchParams(
                decamelizeKeysDeep({
                    q: `*`,
                    sort: "title asc",
                    fq: "title:*",
                    expand: true,
                    documentType: `("field_config")`,
                    collectionId: collectionId,
                })
            );
            damsSearch(searchParams).then((json) => {
                loadedConfigCallback(json['models'])
            });
        } else {
            setExistingMappings([]);
        }
    }, [collectionId]);

    return <Box>
        {getSelectMuseumDialog()}
        {collectionId !== -1 && loaded &&
            <>
                <Stack direction={"row"} sx={{paddingTop: '16px', paddingLeft: '16px', paddingRight: '16px'}}>
                    <Typography variant={"h5"}>
                        {t('titleMetadataMapping', 'Innstillinger: Metadata-mapping')}
                    </Typography>
                    <Box sx={{flexGrow: 1}}/>
                    <Button color={"secondary"} onClick={cancel} disabled={buttonsDisabled}>
                        {t('btnCancel', 'Avbryt')}
                    </Button>
                    <Button variant={"contained"} onClick={save} disabled={buttonsDisabled}>
                        {t('btnSave', 'Lagre')}
                    </Button>
                </Stack>

                <Stack direction={"row"}
                       sx={{
                           padding: '24px',
                           minWidth: '100%',
                           width: '100%',
                           minHeight: '90vh',
                           height: '90vh',
                           overflowY: 'auto',
                           overflowX: 'hidden'
                       }}
                       alignContent={'start'}>

                    {/* Render the config input fields */}
                    <Box sx={{minWidth: '50%'}}>
                        <TabContext value={selectedTabKey}>
                            {/* Render the tabs */}
                            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                {
                                    Object.keys(metadataConfig).map(key => {
                                        return <Tab label={getHrDocumentTypeName(key)} value={key} key={key}/>;
                                    })
                                }
                            </TabList>
                            {Object.keys(metadataConfig).map((key) => {
                                return <TabPanel key={key} value={key}>{getConfigSection(key)}</TabPanel>
                            })}
                        </TabContext>
                    </Box>

                    <Divider orientation={"vertical"} sx={{minHeight: '100%'}}/>

                    {/* Render the edited config */}
                    <Box sx={{
                        minHeight: '100%',
                        height: '100%',
                        minWidth: '50%',
                        backgroundColor: '#ddd',
                        padding: '16px'
                    }}>
                        {getCurrentConfig()}
                    </Box>
                </Stack>
            </>
        }
    </Box>
};