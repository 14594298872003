import PropTypes from "prop-types";
import {Box, Dialog, DialogContent} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {getDmsIdFromModel} from "../utility";
import {orderDownloadUrl} from "../dms/dmsSecurity";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import {EditImageCanvas} from "./EditImageCanvas";


/**
 * Renders an EditImage component that allows the user to edit an image.
 *
 * Info:
 * https://github.com/scaleflex/filerobot-image-editor
 *
 * @param {Object} props - The properties for the EditImage component.
 * @param {Object} props.model - The model object containing information about the image.
 * @return {JSX.Element} The rendered EditImage component.
 */
const EditImage = ({t, model}) => {
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);

    const getFilename = () => {
        return model.content.mediae[0].reference.title;
    };

    const getImageUrl = useCallback(() => {
        const collectionId = model.collectionId;
        const dmsId = getDmsIdFromModel(model);
        if (!collectionId || !dmsId) {
            return;
        }

        orderDownloadUrl({
            collectionId: collectionId,
            dmsId: dmsId,
            filename: 'test.jpg'
        }).then(({url}) => {
            setUrl(url);
            setLoading(false);
        }).catch(e => {
            console.error(e);
        });
    }, [model]);

    const closeDialog = () => {
        setOpenDialog(false);
    };

    useEffect(() => {
        if (!model) {
            return;
        }

        getImageUrl()
    }, [model, getImageUrl]);

    return <Box>
        <Button variant={"contained"}
                color={"primary"}
                disabled={loading || !url}
                onClick={() => setOpenDialog(true)}>
            {t('btnRotateImage', 'Roter (originalstørrelse)')}
        </Button>
        <Dialog open={openDialog} sx={{
            "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                    width: '1024px',
                    maxWidth: "1024px",
                    height: '1024px',
                    maxHeight: '1024px'
                },
            },
        }}>
            <DialogTitle>
                {t('dlgRotateImageTitle', 'Roter bilde')}
            </DialogTitle>
            <DialogContent>
                <EditImageCanvas t={t} url={url} filename={getFilename()}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>
                    {t('btnCancel', 'Avbryt')}
                </Button>
            </DialogActions>
        </Dialog>
    </Box>;
};

EditImage.propTypes = {
    "model": PropTypes.object.isRequired
};

export {EditImage};