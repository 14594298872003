import {useCallback, useState} from "react";
import {SET_DMS_ID, useDocumentDispatch, useDocumentState,} from "../documents/documentContext";
import {orderUploadUrl} from "../dms/dmsSecurity";
import {UPDATE_PROGRESS, useDmsDispatch} from "../dms/dmsContext";
import {PromisePool} from "@supercharge/promise-pool";
import {clientLog} from "../clientLog";
import useDeepCompareEffect from "use-deep-compare-effect";
import axios from "axios";

/**
 * Hook used to upload the selected files, and keep track of their progress.
 * @param collectionId
 */
export const useUploadFiles = (collectionId) => {
    const dmsDispatch = useDmsDispatch();
    const documentDispatch = useDocumentDispatch();
    const {files} = useDocumentState();
    const [uploading, setUploading] = useState(false);

    const bulkSize = 4; // NOTE: No reason to set this higher!

    const updateProgress = useCallback(
        (fileName, progress) => {
            dmsDispatch({
                type: UPDATE_PROGRESS,
                fileName: fileName,
                progress: progress,
            });
        },
        [dmsDispatch]
    );

    const put = (file, progressHandler, json) => {
        return axios
            .put(json.url, file, {
                onUploadProgress: (progressEvent) => {
                    progressHandler(
                        Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    );
                },
            })
            .then(() => ({multimediaId: json.multimediaId}));
    };

    /**
     * Triggered when the file has failed uploading.
     * @param error
     * @param title
     */
    const errorCallback = (error, title) => {
        clientLog('error', error, 'useUploadFiles');
        updateProgress(title, -1);
    };

    const uploadToDams = files => {
        PromisePool
            .withConcurrency(bulkSize)
            .for(files)
            .process(async (file) => {
                return put(file.fileObj, (progress) => updateProgress(file.json.title, progress), file.json);
            })
            .then(res => {
                res.results.forEach(r => {
                    const data = files.find(f => f.json.multimediaId === r.multimediaId);
                    if (data) {
                        const title = data.json.title;
                        const multimediaId = r.multimediaId;
                        updateProgress(data.json.title, 100);
                        documentDispatch({
                            type: SET_DMS_ID,
                            dmsId: multimediaId,
                            name: title
                        });
                    }
                });
                setUploading(false);
            });
    };

    const startUpload = (filesToBeUploaded) => {
        setUploading(true);
        PromisePool
            .withConcurrency(bulkSize)
            .for(filesToBeUploaded)
            .process(async (file) => {
                const data = await orderUploadUrl(collectionId, file.file);
                return {fileObj: file.file, json: {...data, title: file.title}};
            })
            .then((res) => {
                res.errors.forEach(e => {
                    errorCallback(e.message, e.item.title);
                });
                uploadToDams(res.results);
            });
    };

    /**
     * Hook used to queue and upload the selected files.
     */
    useDeepCompareEffect(() => {
        if (uploading) {
            return;
        }

        const filesToBeUploaded = files.filter(f => !f.queued && !f.dmsId);
        if (filesToBeUploaded.length === 0) {
            return;
        }
        startUpload(filesToBeUploaded);
    }, [files, dmsDispatch, collectionId, documentDispatch, updateProgress]);
};
