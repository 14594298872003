import PropTypes from "prop-types";
import {useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {Stack} from "@mui/material";
import {ImageRotator} from "./ImageRotator";
import RotateRightIcon from '@mui/icons-material/RotateRight';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

/**
 * A React component that provides an image editing canvas with rotation and flip functionality.
 *
 * @param {object} props - The properties for the component.
 * @param {string} props.t - The translation function.
 * @param {string} props.filename - The filename of the image.
 * @param {string} props.url - The URL of the image.
 * @return {JSX.Element} The rendered image editing canvas.
 */
const EditImageCanvas = ({t, filename, url}) => {

    const [zoom, setZoom] = useState(1.0);
    const [image, setImage] = useState();
    const [rotation, setRotation] = useState(0);
    const [disableFlipButtons, setDisableFlipButtons] = useState(false);

    const [flipped, setFlipped] = useState(false);

    const CANVAS_CONTAINER_ID = 'image-canvas';

    const imageRotator = useRef();

    const resetFlip = () => {
        imageRotator.current.init(CANVAS_CONTAINER_ID, image);
        setFlipped(false);
    };

    const handleRotateClick = () => {
        rotate();
    };

    const handleFlipXClick = () => {
        if (!flipped) {
            imageRotator.current.flip('x');
            setFlipped(true);
        } else {
            resetFlip();
        }
    };

    const handleFlipYClick = () => {
        if (!flipped) {
            imageRotator.current.flip('y');
            setFlipped(true);
        } else {
            resetFlip();
        }
    };

    const rotate = () => {
        let rotateTo = rotation + 90;
        if (rotateTo === 360) {
            rotateTo = 0;
        }
        imageRotator.current.rotate(rotateTo);
        setRotation(rotateTo);
    };

    /**
     * Saves the canvas as an image and prompts the user to download it.
     *
     * @return {void}
     */
    const saveImage = () => {
        imageRotator.current.save(filename);
    };

    /**
     * Hook used to disable the flip buttons, when the image is rotated.
     */
    useEffect(() => {
        if (!image) {
            return;
        }

        if (image.height > image.width) {
            setDisableFlipButtons(rotation === 90 || rotation === 270);
        } else if (image.height < image.width) {
            setDisableFlipButtons(rotation === 0 || rotation === 180);
        }
    }, [rotation]);

    /**
     * Hook used to load the image.
     */
    useEffect(() => {
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.src = url;

        img.onload = () => {
            const max = 800;
            if (img.width > max || img.height > max) {
                if (img.width > img.height) {
                    setZoom(max / img.width);
                } else {
                    setZoom(max / img.height);
                }
            }

            imageRotator.current = ImageRotator();
            imageRotator.current.init(CANVAS_CONTAINER_ID, img);
        };

        setImage(img);
    }, [url]);

    return <Box>
        <Stack direction={"row"}
               sx={{
                   marginBottom: '16px',
                   width: '100%',
                   justifyContent: 'center'
               }}>
            <Button onClick={handleRotateClick}
                    variant={"contained"}
                    startIcon={<RotateRightIcon/>}>
                {t('btnRotate', 'Roter')}
            </Button>
            <Button onClick={handleFlipXClick}
                    variant={'contained'}
                    startIcon={<ArrowUpwardIcon/>}
                    disabled={disableFlipButtons}
                    sx={{marginLeft: '8px'}}
            >
                {t('btnFlip', 'Flip')}
            </Button>
            <Button onClick={handleFlipYClick}
                    variant={'contained'}
                    startIcon={<ArrowForwardIcon/>}
                    disabled={disableFlipButtons}
                    sx={{marginLeft: '8px'}}
            >
                {t('btnFlip', 'Flip')}
            </Button>
            <Button onClick={saveImage}
                    variant={"contained"}
                    color={"secondary"}
                    startIcon={<FileDownloadIcon/>}
                    sx={{marginLeft: '8px'}}>
                {t('btnDownload', 'Last ned')}
            </Button>
        </Stack>
        <Box sx={{
            zoom: zoom,
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignContent: 'center'
        }}>
            <canvas id="image-canvas"></canvas>
        </Box>
        <canvas id="export-canvas"></canvas>
    </Box>;
};

EditImageCanvas.propTypes = {
    "filename": PropTypes.string,
    "url": PropTypes.string
};

export {EditImageCanvas};