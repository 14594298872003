/**
 * Class containing methods for rotating an image on a canvas, in 90 degrees increments, and saving it as an image.
 * @returns {{init: init, rotate: rotate, save: save}}
 * @constructor
 */
export const ImageRotator = () => {

    let _image;
    let _degrees;
    let _canvas;
    let _ctx;

    let _offscreenCanvas;
    let _offscreenCtx;

    /**
     * Creates a canvas that holds the manipulated image in its original size, used when saving the final image.
     *
     * @private
     * @return {void}
     */
    const _createOffscreenCopy = () => {
        _offscreenCanvas = document.getElementById('export-canvas');
        if (!_offscreenCanvas) {
            throw new Error('Offscreen canvas not found');
        }
        _offscreenCanvas.width = _degrees % 180 === 0 ? _image.width : _image.height;
        _offscreenCanvas.height = _degrees % 180 === 0 ? _image.height : _image.width;
        _offscreenCtx = _offscreenCanvas.getContext('2d');
        if (!_offscreenCtx) {
            throw new Error('Failed to get offscreen canvas context');
        }
        _offscreenCtx.canvas.hidden = true;
        _offscreenCtx.drawImage(_canvas, 0, 0);
    };

    /**
     * Rotate the image by a specified number of degrees.
     *
     * @param {number} degrees - The number of degrees to rotate the image.
     * @return {void}
     */
    const _rotate = (degrees) => {
        _degrees = degrees;

        if (_degrees === 360) {
            _degrees = 0;
        }

        _canvas.width = _degrees % 180 === 0 ? _image.width : _image.height;
        _canvas.height = _degrees % 180 === 0 ? _image.height : _image.width;

        _ctx.save();
        _ctx.translate(_canvas.width / 2, _canvas.height / 2);
        _ctx.rotate(_degrees * Math.PI / 180);
        _ctx.drawImage(_image, _image.width / -2, _image.height / -2);
        _ctx.restore();

        _createOffscreenCopy();
    };

    /**
     * Initializes the canvas with the given image.
     *
     * @param {string} canvasId - The id of the canvas element.
     * @param {object} image - The image object to be rendered on the canvas.
     * @return {void}
     */
    const init = (canvasId, image) => {
        _image = image;
        _canvas = document.getElementById(canvasId);
        if (!_canvas) {
            throw new Error('Canvas not found');
        }
        _ctx = _canvas.getContext('2d');
        if (!_ctx) {
            throw new Error('Failed to get canvas context');
        }
        _rotate(0);
    };

    const rotate = (degrees) => {
        _rotate(degrees);
    };

    const flip = (axis) => {
        let scaleH = axis === 'y' ? -1 : 1, // Set horizontal scale to -1 if flip horizontal
            scaleV = axis === 'x' ? -1 : 1, // Set vertical scale to -1 if flip vertical
            posX = axis === 'y' ? _canvas.width * -1 : 0, // Set x position to -100% if flip horizontal
            posY = axis === 'x' ? _canvas.height * -1 : 0; // Set y position to -100% if flip vertical

        _ctx.save(); // Save the current state
        _ctx.scale(scaleH, scaleV); // Set scale to flip the image
        _ctx.drawImage(_image, posX, posY, _canvas.width, _canvas.height); // draw the image
        _ctx.restore(); // Restore the last saved state

        _offscreenCanvas = document.getElementById('export-canvas');
        if (!_offscreenCanvas) {
            throw new Error('Offscreen canvas not found');
        }
        _offscreenCanvas.width = _image.width;
        _offscreenCanvas.height = _image.height;
        _offscreenCtx = _offscreenCanvas.getContext('2d');
        if (!_offscreenCtx) {
            throw new Error('Failed to get offscreen canvas context');
        }
        _offscreenCtx.canvas.hidden = true;
        _offscreenCtx.drawImage(_canvas, 0, 0);
    };

    /**
     * Saves the image to the user's device.
     *
     * @param {string} filename - The desired filename of the saved image.
     * @return {void}
     */
    const save = (filename) => {
        const imageData = _offscreenCanvas.toDataURL('image/jpeg', 1.0);
        let a = document.createElement('a');
        a.href = imageData;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return {
        init: init,
        rotate: rotate,
        flip: flip,
        save: save
    }
};