import React from "react";
import {Reindex} from "../../index/Reindex";
import {useIsAppAdmin} from "../../auths/useIsAppAdmin";
import {useIsAdminOfAtleastOneMuseum} from "../../auths/useIsAdminOfAtleastOneMuseum";
import {MappingConfig} from "../../metadata/mappingconfig/MappingConfig";
import {MetaMappingProvider} from "../../metadata/MetaMappingContext";
import Box from "@mui/material/Box";

export const AdministrateMetadataMappingRoute = () => {
    const isAppAdmin = useIsAppAdmin();
    const isMuseumAdmin = useIsAdminOfAtleastOneMuseum();
    const museumAdminRoutes = isMuseumAdmin || isAppAdmin;

    if (museumAdminRoutes) {
        return <Box sx={{width: '100%', minWidth: '100%'}}>
            <MetaMappingProvider>
                <MappingConfig/>
            </MetaMappingProvider>
        </Box>;
    } else {
        return <></>;
    }
};

export const AdminReindexRoute = () => {
    const isAppAdmin = useIsAppAdmin();
    if (isAppAdmin) {
        return <Reindex/>;
    } else {
        return <></>;
    }
};
